import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [balanceLoading, setBalanceLoading] = useState(true);

    const fetchBalance = async (userId) => {
        setBalanceLoading(true);
        try {
            const response = await fetch(`http://localhost:8000/api/balance/${userId}`, { credentials: 'include' });
            if (!response.ok) {
                throw new Error(`Error fetching balance: ${response.statusText}`);
            }
            const data = await response.json();
            setBalance(data.balance);
        } catch (error) {
            console.error('Error fetching balance:', error);
        } finally {
            setBalanceLoading(false);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch('http://localhost:8000/api/current_user', { credentials: 'include' });
                if (!response.ok) {
                    throw new Error(`Error fetching user: ${response.statusText}`);
                }
                const contentType = response.headers.get("content-type");

                if (!contentType || !contentType.includes("application/json")) {
                    throw new SyntaxError("Invalid JSON");
                }

                const data = await response.json();
                if (data.user) {
                    setUser(data.user);
                    await fetchBalance(data.user.id); // Fetch balance after setting user
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const login = async (userData) => {
        setUser(userData);
        await fetchBalance(userData.id); // Fetch balance on login
    };

    const logout = async () => {
        await fetch('http://localhost:8000/api/logout', { method: 'POST', credentials: 'include' });
        setUser(null);
        setBalance(null);
        setBalanceLoading(true); // Reset balance loading state
    };

    return (
        <AuthContext.Provider value={{ user, balance, isAuthenticated: !!user, login, logout, loading, balanceLoading }}>
            {children}
        </AuthContext.Provider>
    );
};
