import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
// import Events from "./components/Events temporary disabled";
import UsersList from "./components/Admin/UsersList";
import PasswordRecovery from "./components/PasswordRecovery";
import ResetPassword from "./components/ResetPassword";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './components/Admin/Login'; 
import ProtectedRoute from './components/Admin/ProtectedRoute'; 
import CryptoOverview from './components/CryptoOverview';
import Dashboard from './components/Dashboard'; 

function App() {
    return (
        <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
            <AuthProvider>
                <Router>
                    <ErrorBoundary>
                        <NavBar />
                        <Routes>
                            <Route path="/" element={<MainPage />} />
                            <Route path="/admin/login" element={<Login />} /> 
                            <Route path="/users" element={<UsersList />} />
                            <Route path="/recover-password" element={<PasswordRecovery />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            {/* Protected Admin Routes */}
                            <Route 
                                path="/admin/users" 
                                element={
                                    <ProtectedRoute>
                                        <UsersList />
                                    </ProtectedRoute>
                                } 
                            />
                        </Routes>
                    </ErrorBoundary>
                </Router>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

const MainPage = () => (
    <>
        <div className='mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl'>
            {/* <Events /> */}
            <CryptoOverview/>
        </div>
        <Footer />
    </>
);

export default App;
