import React, { useState } from 'react';
import BetGrid from './BetPanel';

const Tabs = () => {
    const [openTab, setOpenTab] = useState(1);

    const activeClasses = 'border-b-4 border-yellow-500 text-white rounded-t';
    const inactiveClasses = 'text-gray-400 hover:text-gray-300';

    return (
        <div className="p-6">
            <ul className="flex">
                <li 
                    onClick={() => setOpenTab(1)} 
                    className={`relative -mb-px mr-1 ${openTab === 1 ? '-mb-px' : ''}`}
                >
                    <button 
                        className={`inline-block py-2 px-4 font-semibold ${openTab === 1 ? activeClasses : inactiveClasses}`}
                    >
                        Active
                    </button>
                    <div className="pulsating-circle"></div>
                </li>
                <li 
                    onClick={() => setOpenTab(2)} 
                    className={`mr-1 ${openTab === 2 ? '-mb-px' : ''}`}
                >
                    <button 
                        className={`inline-block py-2 px-4 font-semibold ${openTab === 2 ? activeClasses : inactiveClasses}`}
                    >
                        Coming Soon
                    </button>
                </li>
                <li 
                    onClick={() => setOpenTab(3)} 
                    className={`mr-1 ${openTab === 3 ? '-mb-px' : ''}`}
                >
                    <button 
                        className={`inline-block py-2 px-4 font-semibold ${openTab === 3 ? activeClasses : inactiveClasses}`}
                    >
                        {/* You can add text or leave it empty */}
                    </button>
                </li>
            </ul>
            <div className="w-full mt-4">
                {openTab === 1 && (
                    <div className="p-4 bg-cian-300  rounded-md"><BetGrid/></div>
                )}
                {openTab === 2 && (
                    <div className="p-4 rounded-md">Tab #2</div>
                )}
            </div>
        </div>
    );
};

export default Tabs;