import React, { useState } from 'react';
import logo from '../assets/images/swaggybetlogo.png';
import LoginForm from './LoginForm';
import { useAuth } from '../AuthContext';
import { MagnifyingGlassIcon, BellIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
    const [showModal, setShowModal] = useState(false);
    const [formMode, setFormMode] = useState('login');
    const { user, isAuthenticated, logout, balance, balanceLoading } = useAuth();
    const navigate = useNavigate();

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const openLoginForm = () => {
        setFormMode('login');
        toggleModal();
    };

    const openRegisterForm = () => {
        setFormMode('register');
        toggleModal();
    };

    const handleBalanceClick = () => {
        navigate('/dashboard');
    };

    const handleDepositClick = () => {
        navigate('/deposit'); // Redirect to deposit page
    };

    return (
        <>
            <header className="bg-stone-800 z-30 fixed top-0 left-0 w-full mb-10">
                <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="flex-1 flex items-center gap-12">
                            <a className="block text-teal-600" href="/">
                                <span className="sr-only">Home</span>
                                <img src={logo} className="h-9" alt="Swaggycoins Logo" />
                            </a>

                            <div className="hidden md:flex md:flex-1">
                                <div className="relative flex items-center">
                                    <MagnifyingGlassIcon className="absolute left-3 h-3 w-3 text-gray-500" />
                                    <input
                                        type="text"
                                        placeholder="Search markets"
                                        className="pl-10 pr-3 py-1.5 h-8 bg-stone-600 text-xs text-stone-300 rounded-full w-full md:w-64 lg:w-80"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="md:flex md:items-center md:gap-12">
                            <nav aria-label="Global" className="hidden md:block">
                                <ul className="flex items-center gap-6 text-sm">
                                    {/* Add more navigation links here */}
                                </ul>
                            </nav>

                            <div className="flex items-center gap-4">
                                <div className="sm:flex sm:gap-1 items-center">
                                    {!isAuthenticated ? (
                                        <>
                                            <button
                                                className="rounded-md hover:bg-stone-600 px-3 mx-1 py-2 text-xs font-medium text-white"
                                                onClick={openLoginForm}
                                            >
                                                Login
                                            </button>

                                            <button
                                                className="rounded-md bg-yellow-400 hover:bg-yellow-500 px-4 mx-1 py-2 text-xs font-medium"
                                                onClick={openRegisterForm}
                                            >
                                                Sign Up
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button 
                                                className="hidden md:block rounded-full bg-stone-700 px-5 mx-1 py-2 text-sm font-medium text-white"
                                                onClick={handleBalanceClick} // Handle balance click
                                            >
                                                Balance: {balanceLoading ? 'Loading...' : `$${balance !== null ? balance.toFixed(2) : '0.00'}`}
                                            </button>

                                            <button 
                                                className="hidden md:block rounded-md bg-green-500 hover:bg-green-600 px-4 mx-1 py-2 text-sm font-medium text-white"
                                                onClick={handleDepositClick} // Handle deposit click
                                            >
                                                Deposit
                                            </button>

                                            <div className="flex items-center gap-2">
                                                <button className="relative rounded-full p-1 text-gray-400 hover:text-white focus:outline-none">
                                                    <span className="sr-only">View notifications</span>
                                                    <BellIcon aria-hidden="true" className="h-6 w-6" />
                                                </button>

                                                <Menu as="div" className="relative">
                                                    <div>
                                                        <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none">
                                                            <span className="sr-only">Open user menu</span>
                                                            <UserCircleIcon aria-hidden="true" className="h-8 w-8 text-gray-400 hover:text-white" />
                                                        </MenuButton>
                                                    </div>
                                                    <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1">
                                                        <span className="block px-4 pt-2 text-sm text-gray-700">
                                                            {user.username}
                                                        </span>
                                                        <button 
                                                            className="block px-4 py-2 text-sm text-gray-700"
                                                            onClick={handleBalanceClick} // Handle balance click in the dropdown
                                                        >
                                                            Balance: {balanceLoading ? 'Loading...' : `$${balance !== null ? balance.toFixed(2) : '0.00'}`}
                                                        </button>
                                                        <MenuItem className='border-t-2'>
                                                            <a href="/" className="block px-4 py-2 text-sm text-gray-700">
                                                                Your Profile
                                                            </a>
                                                        </MenuItem>
                                                        <MenuItem className='border-t-2'>
                                                            <a href="/" className="block px-4 py-2 text-sm text-gray-700">
                                                                Betting history
                                                            </a>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <a href="/" className="block px-4 py-2 text-sm text-gray-700">
                                                                Settings
                                                            </a>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <button
                                                                onClick={logout}
                                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700"
                                                            >
                                                                Logout
                                                            </button>
                                                        </MenuItem>
                                                    </MenuItems>
                                                </Menu>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {showModal && (
                <LoginForm
                    onClose={toggleModal}
                    mode={formMode}
                    setFormMode={setFormMode} // Pass setFormMode as a prop
                />
            )}
        </>
    );
};

export default NavBar;
