import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    if (!targetDate) {
      console.error("Invalid targetDate");
      setTimeLeft("Invalid date");
      return;
    }

    const countDownDate = new Date(targetDate).getTime();

    // Verify if targetDate is valid
    if (isNaN(countDownDate)) {
      console.error("Invalid targetDate format");
      setTimeLeft("Invalid date");
      return;
    }

    // Update the countdown every 1 second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft("EXPIRED");
      } else {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="flex justify-center">
      <div className="rounded-lg  px-4 py-2">
        <div className="flex flex-col gap-2 items-center justify-between">
          <span id="countdown" className="text-lg sm:text-sm font-semibold">
            {timeLeft}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
