import React, { useState, useEffect, useRef } from "react";
import "react-range-slider-input/dist/style.css";
import Countdown from "./Countdown";
import { Range } from "react-range";
import Modal from "react-modal";
import solanaImage from "../assets/images/solana_2.png";
import litecoinImage from "../assets/images/OIG1.jpeg";
import bitcoinImage from "../assets/images/bitcoinart.jpeg";

// Reusable UI components
const InputField = ({ value, onChange, placeholder, className, ...props }) => {
  const inputRef = useRef(null);

  return (
    <input
      ref={inputRef}
      type="tel" // type="tel" to bring up the numeric keypad
      inputMode="numeric"
      pattern="[0-9]*"
      placeholder={placeholder}
      className={`no-spinner border-0 rounded-md py-2 px-3 w-full text-gray-800 bg-gray-200 focus:ring-2 focus:ring-yellow-500 ${className}`}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

const Button = ({ children, onClick, className, ...props }) => (
  <button
    className={`bg-blue-500 hover:bg-blue-600 rounded-md py-2 px-4 text-white transition-all duration-150 ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

const styles = `
  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-spinner {
    -moz-appearance: textfield;
  }
`;

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#f9f9f9",
    color: "#333",
    borderRadius: "12px",
    border: "none",
    padding: "20px",
    width: "320px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const BetPanel = ({ targetDate, balance, setBalance, coin }) => {
  const [price, setPrice] = useState(null);
  const [betAmount, setBetAmount] = useState(50);
  const [potentialWinnings, setPotentialWinnings] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(
          `https://www.binance.com/api/v3/ticker/price?symbol=${coin}USDT`
        );
        const data = await response.json();
        setPrice(parseFloat(data.price));
      } catch (error) {
        console.error("Error fetching live price:", error);
        setPrice(60000);
      }
    };

    fetchPrice();
  }, [coin]);

  const handlePriceChange = (amount) =>
    setPrice((prevPrice) => prevPrice + amount);

  const handleBetAmountChange = (value) => {
    const parsedValue = value === "" || isNaN(value) ? 0 : parseFloat(value);
    setBetAmount(parsedValue);
  };

  const handleBetSubmit = () => {
    setIsModalOpen(true);
  };

  const confirmBet = async () => {
    setIsModalOpen(false);
    const amount = parseFloat(betAmount);
    if (amount > balance) {
      alert("Insufficient balance.");
      return;
    }

    try {
      const response = await fetch("/api/place_bet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          predicted_outcome: price,
          amount,
        }),
      });

      if (response.ok) {
        alert("Bet placed successfully!");
        setBalance((prevBalance) => prevBalance - amount);
      } else {
        alert("Error placing bet.");
      }
    } catch (error) {
      console.error("Error placing bet:", error);
      alert("Error placing bet.");
    }
  };

  useEffect(() => {
    setPotentialWinnings(betAmount * 1.2);
  }, [betAmount]);

  return (
    <div className="flex flex-col items-center justify-center h-full w-full bg-gray-100 shadow-lg rounded-lg p-4 space-y-4 text-gray-800">
      <style>{styles}</style>
      <div className="flex items-center justify-center w-full">
        <div className="flex-1 truncate">
          <h3 className="text-xl font-semibold text-center">
            {coin} bet results in: <Countdown targetDate={targetDate} />
          </h3>
        </div>
      </div>
      <div className="relative flex items-center justify-center w-full">
        <InputField
          value={price !== null ? price : "Loading..."}
          onChange={(e) => setPrice(parseFloat(e.target.value))}
          placeholder="Price input"
        />
        <div className="absolute right-2 flex space-x-2">
          <Button onClick={() => handlePriceChange(-1)}>-</Button>
          <Button onClick={() => handlePriceChange(1)}>+</Button>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-4 w-full">
        <div className="relative w-1/2">
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
            $
          </span>
          <InputField
            value={betAmount === 0 ? "" : betAmount}
            onChange={(e) => handleBetAmountChange(e.target.value)}
            placeholder="Amount to bet"
            className="pl-7"
            min="1"
            max="100"
          />
        </div>
        <div className="w-1/2">
          <Range
            step={1}
            min={1}
            max={100}
            values={[betAmount]}
            onChange={(values) => handleBetAmountChange(values[0])}
            renderTrack={({ props, children }) => {
              const percentage =
                betAmount > 0 ? ((betAmount - 1) / 99) * 100 : 0;
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "100%",
                    background: `linear-gradient(90deg, #3b82f6 ${percentage}%, #e0e0e0 ${percentage}%)`,
                    borderRadius: "5px",
                  }}
                >
                  {children}
                </div>
              );
            }}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "26px",
                  width: "26px",
                  backgroundColor: "#3b82f6",
                  borderRadius: "50%",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
                }}
              />
            )}
          />
        </div>
      </div>
      <Button
        onClick={handleBetSubmit}
        disabled={
          price === null || betAmount === "" || isNaN(parseFloat(betAmount))
        }
        className="w-full font-semibold mt-4"
      >
        Bet{" "}
        <span className="block text-sm">
          To win ${potentialWinnings.toFixed(2)}
        </span>
      </Button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customModalStyles}
      >
        <h2 className="text-lg font-semibold">Confirm Your Bet</h2>
        <p className="mt-4">
          You're about to bet ${betAmount} on {coin} at a price of ${price}.
        </p>
        <p>If you win, you'll receive ${potentialWinnings.toFixed(2)}.</p>
        <div className="flex justify-end space-x-2 mt-4">
          <Button onClick={confirmBet}>Confirm</Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-500 hover:bg-gray-600"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const FlipCard = ({
  targetDate,
  balance,
  setBalance,
  title,
  image,
  coin,
  backgroundImage,
}) => {
  return (
    <div className="flip-card w-full h-full">
      <div className="flip-card-inner h-full flex items-center justify-center">
        <div
          className="flip-card-front relative h-full w-full flex items-center justify-center shadow-md rounded-lg"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div>
          <div className="relative flex flex-col items-center justify-center w-full h-full space-y-4">
            {image && (
              <img
                src={image}
                alt={title}
                className="w-16 h-16 rounded-full shadow-md"
              />
            )}
            <h3 className="text-2xl font-bold text-center text-white drop-shadow-lg">
              {title}
            </h3>
            <p className="text-white text-sm">{coin}</p>
            <div className="text-4xl font-semibold text-center text-white drop-shadow-lg">
              <Countdown targetDate={targetDate} />
            </div>
            <span className="inline-flex items-center justify-center rounded-full bg-green-500 px-2 py-1 text-xs font-semibold text-white drop-shadow-lg">
              Open
            </span>
          </div>
        </div>
        <div className="flip-card-back h-full w-full">
          <BetPanel
            targetDate={targetDate}
            balance={balance}
            setBalance={setBalance}
            coin={coin}
          />
        </div>
      </div>
    </div>
  );
};

const BetGrid = () => {
  const [balance, setBalance] = useState(100);

  return (
    <div className="flex justify-center items-center mt-16">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <div className="w-64 h-80">
          <FlipCard
            targetDate={new Date(Date.now() + 86400000)}
            balance={balance}
            setBalance={setBalance}
            title="Bitcoin"
            coin="BTC"
            backgroundImage={bitcoinImage}
          />
        </div>
        <div className="w-64 h-80">
          <FlipCard
            targetDate={new Date(Date.now() + 86400000)}
            balance={balance}
            setBalance={setBalance}
            title="Solana"
            coin="SOL"
            backgroundImage={solanaImage}
          />
        </div>
        <div className="w-64 h-80">
          <FlipCard
            targetDate={new Date(Date.now() + 86400000)}
            balance={balance}
            setBalance={setBalance}
            title="Litecoin"
            coin="LTC"
            backgroundImage={litecoinImage}
          />
        </div>
      </div>
    </div>
  );
};

export default BetGrid;
