import React from 'react'
import Tabs from './Tabs'

const CryptoOverview = () => {
  return (
    <div className='mt-20'>
        <Tabs/>
    </div>
  )
}

export default CryptoOverview