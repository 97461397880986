import React, { useState } from "react";
import { useAuth } from "../AuthContext";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { GoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = ({ onClose, mode, setFormMode }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');

    const { login } = useAuth();

    const isLogin = mode === 'login';

    const handleSubmit = async (event) => {
        event.preventDefault();
        const endpoint = isLogin ? "http://localhost:8000/api/login" : "http://localhost:8000/api/register";
        const payload = isLogin
            ? { email, password, captchaToken }
            : { username, email, password, confirm_password: confirmPassword, captchaToken };

        console.log('Submitting payload:', payload);

        setLoading(true);
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });

            let data;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                data = await response.json();
            } else {
                const text = await response.text();
                throw new Error(text || `HTTP error! status: ${response.status}`);
            }

            setLoading(false);
            if (response.ok) {
                setSuccess(data.message);
                setError('');
                if (isLogin) {
                    login(data.user);
                    onClose();
                } else {
                    // handle registration success
                }
            } else {
                console.error('API Error:', data);
                setError(data.message || 'Invalid credentials');
                setSuccess('');
            }
        } catch (error) {
            setLoading(false);
            console.error('Fetch Error:', error);
            setError(error.message || "An error occurred, please try again later.");
            setSuccess('');
        }
    };

    const handleGoogleLoginSuccess = (response) => {
        console.log('Google Login Success:', response);
        // Handle the Google login response, e.g., send to your backend for verification
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div
            id="login-popup"
            tabIndex="-1"
            className="bg-black/50 fixed inset-0 flex items-center justify-center z-50"
        >
            <div className="relative p-4 w-full max-w-md bg-white rounded-lg shadow-md">
                <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1.5"
                    onClick={onClose}
                >
                    <XMarkIcon className="h-6 w-6 text-gray-500" />
                    <span className="sr-only">Close popup</span>
                </button>

                <div className="p-6">
                    <div className="text-center mb-4">
                        <h2 className="text-2xl font-semibold text-slate-900">
                            {isLogin ? "Login to your account" : "Create an account"}
                        </h2>
                        <p className="text-sm text-slate-600 mt-2">
                            {isLogin
                                ? "You must be logged in to perform this action."
                                : "Sign up to get started."}
                        </p>
                    </div>

                    {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                    {success && <p className="text-green-500 text-center mb-4">{success}</p>}

                    <GoogleLogin
                        clientId="YOUR_GOOGLE_CLIENT_ID"
                        onSuccess={handleGoogleLoginSuccess}
                        onFailure={handleGoogleLoginFailure}
                        render={(renderProps) => (
                            <button
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                className="inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60 mt-4"
                            >
                                <img
                                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                                    alt="Google"
                                    className="h-[18px] w-[18px]"
                                />
                                Continue with Google
                            </button>
                        )}
                    />

                    <div className="flex w-full items-center gap-2 py-6 text-sm text-slate-600">
                        <div className="h-px w-full bg-slate-200"></div>
                        OR
                        <div className="h-px w-full bg-slate-200"></div>
                    </div>

                    <form className="space-y-4" onSubmit={handleSubmit}>
                        {!isLogin && (
                            <>
                                <label htmlFor="username" className="sr-only">Username</label>
                                <input
                                    name="username"
                                    type="text"
                                    required
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                                    placeholder="Username"
                                />
                            </>
                        )}
                        <label htmlFor="email" className="sr-only">Email address</label>
                        <input
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                            placeholder="Email Address"
                        />
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                            placeholder="Password"
                        />
                        {!isLogin && (
                            <>
                                <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
                                <input
                                    name="confirm-password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                                    placeholder="Confirm Password"
                                />
                            </>
                        )}
                        <ReCAPTCHA
                            sitekey="YOUR_RECAPTCHA_SITE_KEY"
                            onChange={handleCaptchaChange}
                        />
                        <button
                            type="submit"
                            className={`w-full py-3 rounded-lg bg-black text-white font-medium text-sm focus:ring-2 focus:ring-black ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? "Loading..." : (isLogin ? "Continue" : "Sign Up")}
                        </button>
                    </form>

                    <div className="mt-6 text-center text-sm text-slate-600">
                        {isLogin ? (
                            <>
                                Don't have an account?{" "}
                                <button
                                    onClick={() => setFormMode('register')}
                                    className="font-medium text-blue-600 hover:underline"
                                >
                                    Sign up
                                </button>
                            </>
                        ) : (
                            <>
                                Already have an account?{" "}
                                <button
                                    onClick={() => setFormMode('login')}
                                    className="font-medium text-blue-600 hover:underline"
                                >
                                    Login
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
