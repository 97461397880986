import React from 'react';
import { useAuth } from '../AuthContext';

const Dashboard = () => {
    const { user, balance, balanceLoading } = useAuth();

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6">
                <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Welcome, {user?.username}!</h1>
                </div>
                <div className="text-center mb-4">
                    <p className="text-xl text-gray-600">Your current balance is:</p>
                    <p className="text-3xl font-semibold text-green-500">
                        {balanceLoading ? 'Loading...' : `$${balance !== null ? balance.toFixed(2) : '0.00'}`}
                    </p>
                </div>
                <div className="mt-6 flex justify-center">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-sm">
                        Add Funds
                    </button>
                </div>
                <div className="mt-4 flex justify-center">
                    <button className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-md shadow-sm">
                        Withdraw
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
